import React from 'react'
import {documentToReactComponents} from "@contentful/rich-text-react-renderer"

export default ({ data }) => {
  if (!data) {
    return null
  }
  return (
    <div className="tarifa-line">
      <h3>{data.titulo}</h3>
      {documentToReactComponents(data.texto.json)}
      <ul className="pricing-items row reset-list">
        {data.tarifas.map((tarifa, index) => {
          return (
              <li className="columns small-6 medium-4 large-3" key={index}>
                <div className="card">
                  <p className="price">{tarifa.precio}<span>€<small>{tarifa.precio_mensual ? '/mes' : ''}</small></span>
                  </p>
                  <div className="card-divider">
                    <p className="name">{tarifa.nombre_tarifa}</p>
                  </div>
                </div>
              </li>
          )
        })}
      </ul>
    </div>
  )
}
