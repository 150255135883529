import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from "../components/layout"
import PricingList from '../components/pricing-list'
import ClaimFreeClass from '../components/claim-free-class'

class HorariosTarifas extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const page = get(this, 'props.data.contentfulHorariosYTarifas')
    const metatags = get(this, 'props.data.contentfulHorariosYTarifas.metatags')

    return (
      <Layout>
        <Helmet>
          <title>{metatags ? metatags.meta_title || page.titulo : page.titulo } | {siteTitle}</title>
          {metatags && metatags.meta_description &&
            <meta name="description" content={metatags.meta_description.meta_description} />
          }
        </Helmet>
        <div className="horarios-page">
          <div className="timetables">
            <div className="row">
              <div className="columns small-12">
                <h3>Horarios</h3>
                {page.texto_horarios &&
                  <p>{page.texto_horarios.texto_horarios}</p>
                }
                {page.pdf_horarios &&
                  <a href={page.pdf_horarios.file.url} target="_blank" className="btn-primary">
                    Descarga
                  </a>
                }
              </div>
            </div>
          </div>

          <div className="tarifas">
            <div className="row">
              <div className="columns small-12">
                {page.tarifas.map((tarifa) => {
                  return (
                    <PricingList data={tarifa} />
                  )
                })}
              </div>
            </div>
          </div>

          <ClaimFreeClass />
        </div>

      </Layout>
    )
  }
}

export default HorariosTarifas

export const pageQuery = graphql`
  query HorariosTarifasQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulHorariosYTarifas {
      pdf_horarios {
        file {
          url
        }
      }
      texto_horarios {
        texto_horarios
      }
      tarifas {
        tarifas {
          precio
          precio_mensual
          nombre_tarifa
        }
        titulo
        texto {
          json
        }
      }
      metatags {
        meta_title
        meta_description {
          meta_description
        }
      }
    }
  }
`
